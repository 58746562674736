import React, { useEffect } from 'react';
import { AppStore, Partner, fetchPartner, useTitle } from '../state';
import { Card, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';
import ReactMarkdown from 'react-markdown';

interface PathParams {
  id: string;
}

export default function PartnerDetails(props: RouteComponentProps<PathParams>) {
  useTitle('Partner Offers', '/app/partners');
  let params = props.match.params;
  let id = parseInt(params.id, 10);
  let partner: Partner | undefined = AppStore.useState(
    (s) => s.partnerDetails[id],
    [id]
  );

  useEffect(() => {
    fetchPartner(id);
  }, [id]);

  if (partner == null) {
    return null;
  }

  console.log(partner);

  return (
    <div>
      <BannerImage src={partner.banner || partner.image} />
      <BrandsContainer>
        <h2>Offer</h2>
        <StyledCard key={partner.partnerId}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ fontSize: '20px', fontWeight: 'bold' }}
            >
              {partner.name}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              <ReactMarkdown>{partner.description}</ReactMarkdown>
            </Typography>
            <div>
              {partner.locations.length > 0 &&
                partner.locations.map((l) => (
                  <LocationContainer key={l.partnerLocationId}>
                    <RoomIcon style={{ color: '#888', marginTop: '3px' }} />
                    <LocationNameContainer>
                      <p>{l.name}</p>
                      <a href={l.url} target="_blank" rel="noreferrer">
                        Get directions
                      </a>
                    </LocationNameContainer>
                  </LocationContainer>
                ))}
            </div>
          </CardContent>
        </StyledCard>
      </BrandsContainer>
    </div>
  );
}

let BannerImage = styled.img`
  width: 100%;
`;

let BrandsContainer = styled.div`
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

  p {
    margin-bottom: 10px;
  }
`;

let LocationNameContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #888;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: var(--main-color);
    opacity: 0.8;
    font-size: 14px;
  }
`;

let LocationContainer = styled.div`
  display: flex;
  gap: 10px;
  border-top: 1px solid #ddd;
  padding: 15px 0;
  &:first-child {
    margin-top: 20px;
  }
`;
