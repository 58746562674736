import styled from 'styled-components';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Avatar } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { AppStore, useTitle, refreshMainData } from '../state';
import defaultBgImage from '../imgs/home-header.jpg';

export default function Home() {
  useTitle('Home');
  let levels = AppStore.useState((s) => s.main?.levels || []);
  let me = AppStore.useState((s) => s.main?.me);
  let site = AppStore.useState((s) => s.main?.site);
  let events = AppStore.useState((s) => s.main?.nextEvents || []);
  let history = useHistory();
  let eventCategories = AppStore.useState((s) => s.main?.eventCategories || []);

  if (me == null) return null;

  let level = levels.find((l) => l.level === me?.level) || {
    level: 1,
    name: '',
  };
  let maxLevel = levels[levels.length - 1]?.level || 1;
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  let bgStyle = {
    background: `url('${site?.homepageImage || defaultBgImage}') 50% 100%`,
    backgroundSize: 'cover',
  };

  return (
    <Wrap>
      <PullToRefresh onRefresh={() => refreshMainData()} pullingContent="">
        <>
          <div className="background-container" style={bgStyle}>
            <Avatar alt={`${me.firstName}'s image`} src={me.image} />
          </div>
          <h2>
            {me.firstName} {me.lastName}
          </h2>
          <p className="gradeLevel">
            <span>{level.name}</span>
            {site?.showLevelStars && (
              <Rating
                name="read-only"
                value={level.level}
                max={maxLevel}
                readOnly
              />
            )}
          </p>

          <div>
            {events.length > 0 &&
              events.map((event, i) => (
                <Event
                  key={event.eventId}
                  onClick={() =>
                    event && history.push(`/app/event/${event.eventId}`)
                  }
                >
                  {i === 0 && (
                    <p style={{ marginBottom: '10px' }}>Your next event:</p>
                  )}
                  <p>{format(event.startsAt, 'HH:mm eee, d LLL yyyy')}</p>
                  <h3>{event.name}</h3>
                  <p>at {event.locationName}</p>
                </Event>
              ))}

            {events.length === 0 && (
              <Event className="empty">
                <i>There are no booked events.</i>
              </Event>
            )}
          </div>
        </>
      </PullToRefresh>

      <Foot className={site?.useIconsOnFooter ? '' : 'border'}>
        {eventCategories.length !== 0 ? (
          eventCategories.map((c) => (
            <Link
              key={c.categoryId}
              to={`/app/book/${c.categoryId}/${year}/${month}`}
            >
              {site?.useIconsOnFooter ? (
                <Avatar
                  variant="rounded"
                  alt={`${c.name}'s image`}
                  src={c.logo}
                />
              ) : (
                <span>{c.name}</span>
              )}
            </Link>
          ))
        ) : (
          <>
            <Link to={`/app/book/drive/${year}/${month}`}>Book drive</Link>
            <Link to={`/app/book/event/${year}/${month}`}>Book event</Link>
          </>
        )}
      </Foot>
    </Wrap>
  );
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);

  .background-container {
    width: 100%;
    padding-top: 50%; /* trick to create a desired aspect-ratio */
    background: url('${defaultBgImage}') 50% 100%;
    background-size: cover;
    position: relative;
    margin-bottom: 85px;
  }

  .gradeLevel {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-variant: smallcaps;
  }

  .gradeLevel > span:first-child {
    font-size: 1em;
    height: 1.3em;
    margin-right: 8px;
  }

  .background-container > .MuiAvatar-root {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 120px;
    width: 120px;
    border: 6px solid #444;
    box-shadow: inset 0px 0px 0px 4px #444;
    border-radius: 50%;
    transform: translate(-50%, 50%);
  }

  .background-container > .MuiAvatar-root img {
    border-radius: 50%;
  }

  .MuiRating-root {
    font-size: 1.3em;
    display: flex;
    justify-content: center;
  }

  h2 {
    text-align: center;
    line-height: 1.3em;
    margin: 0 0 10px 0;
  }
`;

let Event = styled.div`
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
  }

  text-align: center;
  &:first-child {
    margin-top: 30px;
  }
  &:last-child {
    margin-bottom: 30px;
  }
  padding: 10px 0;

  h3 {
    font-weight: bold;
    text-transform: uppercase;
  }

  border-top: 2px solid #bbb;

  &:last-child {
    border-bottom: 2px solid #bbb;
  }

  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

let Foot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  color: white;
  bottom: 0;
  height: 65px;
  flex: 65px 0 0;
  margin-top: auto;
  width: 100%;
  max-width: 600px;
  background-color: var(--main-color);
  padding: 5px 5px;
  gap: 5px;

  a,
  a:hover,
  a:visited,
  a:active {
    flex: 1;
    font-size: 0.75em;
    color: white;
    text-decoration: none;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-align: center;
    position: relative;
    min-height: 40px;
  }

  &.border a:not(:last-child):before {
    position: absolute;
    right: 0;
    display: block;
    content: '';
    width: 2px;
    height: calc(100% - 15px);
    background: white;
  }
`;
