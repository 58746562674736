import React, { useEffect } from 'react';
import { AppStore, fetchPartners, useTitle } from '../state';
import { Avatar } from '@material-ui/core';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

export default function Partners() {
  useTitle('Partner Offers', '/app');
  let partners = AppStore.useState((s) => s.partners);
  let site = AppStore.useState((s) => s.main?.site);
  let image = site?.partnersImage;
  let history = useHistory();

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <div>
      <BannerImage src={image} />
      <BrandsContainer>
        <h2>Brands</h2>
        <PartnersWrap>
          {partners.length > 0 &&
            partners.map((p) => (
              <AvatarContainer key={p.partnerId}>
                <Avatar
                  alt={p.name}
                  src={p.image}
                  variant="rounded"
                  onClick={() => history.push(`/app/partners/${p.partnerId}`)}
                />
              </AvatarContainer>
            ))}
        </PartnersWrap>
      </BrandsContainer>
    </div>
  );
}

let BannerImage = styled.img`
  width: 100%;
  aspect-ratio: 2 / 1;
  object-fit: cover;
`;

let BrandsContainer = styled.div`
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let PartnersWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  .MuiAvatar-root,
  img {
    max-width: 120px;
    width: 100%;
    max-height: 120px;
    height: 100%;
  }
`;

let AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
