import React, { useEffect } from 'react';
import { AppStore, fetchNews, useTitle, News } from '../state';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  IconButtonProps,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

export default function NewsPage() {
  useTitle('Broadcast & News', '/app');
  let news = AppStore.useState((s) => s.news);
  let site = AppStore.useState((s) => s.main?.site);
  let image = site?.broadcastImage;

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div>
      <BannerImage src={image} />
      <NewsWrap>
        {news.map((n) => (
          <NewsCard key={n.newsId} news={n} />
        ))}
      </NewsWrap>
    </div>
  );
}

let BannerImage = styled.img`
  width: 100%;
  aspect-ratio: 2 / 1;
  object-fit: cover;
`;

let NewsWrap = styled.div`
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
        margin: 10px;
  }

      img.MuiCardMedia-root {
        object - fit: contain;
  }
`;

let StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

  p {
    margin-bottom: 10px;
  }
`;

function NewsCard(props: { news: News }) {
  let n = props.news;
  const [expanded, setExpanded] = React.useState(false);
  const canExpand = n.body !== '';

  return (
    <StyledCard style={{ width: '100%' }}>
      {n.photo && (
        <div style={{ paddingTop: '56.25%', position: 'relative' }}>
          <CardMedia
            component="img"
            height="100%"
            style={{
              backgroundColor: n.photoBackgroundColor,
              objectFit: n.photoCover ? 'cover' : 'contain',
              position: 'absolute',
              top: 0,
            }}
            image={n.photo}
          />
        </div>
      )}

      <CardContent style={{ paddingBottom: 0 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{ fontSize: '20px' }}
        >
          {n.title}
        </Typography>
        {expanded ? (
          <Typography
            variant="body2"
            style={{ fontSize: '12px', color: '#666' }}
          >
            <ReactMarkdown>{n.body}</ReactMarkdown>
          </Typography>
        ) : (
          <Typography
            variant="body2"
            style={{ fontSize: '12px', color: '#666' }}
          >
            {n.summary}
          </Typography>
        )}
        <Typography
          variant="caption"
          component="div"
          style={{ marginTop: expanded ? '16px' : 0, color: '#666' }}
        >
          <i>Published at: {new Date(n.publishedAt).toLocaleDateString()}</i>
        </Typography>
      </CardContent>
      <CardActions>
        <Wrap>
          {n.externalLink && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              style={{
                marginRight: n.document ? '10px' : 0,
                backgroundColor: 'var(--main-color)',
                color: 'white',
              }}
              href={n.externalLink}
            >
              {n.externalLink.match(/youtube.com|youtu.be/)
                ? 'View on YouTube'
                : 'Open'}
            </Button>
          )}

          {n.document && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              style={{ backgroundColor: 'var(--main-color)', color: 'white' }}
              href={n.document}
            >
              Download
            </Button>
          )}

          {canExpand && (
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon fontSize="large" />
            </ExpandMore>
          )}
        </Wrap>
      </CardActions>
    </StyledCard>
  );
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
}));

let Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
